// LoginForm.js
import React, { useState, useEffect } from 'react';
import { getElder } from './Airtable';

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const elder = await getElder(email);
    if (elder) {
      localStorage.setItem('email', email);
      console.log('Elder object:', elder); // Add this line
      onLogin(elder);
    } else {
      alert('Elder not found. Please check your email.');
    }
  };

  return (
    <div className="login-box">
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginForm;
