import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TranslationVerification from './TranslationVerification';
import LanguageSelection from './LanguageSelection';
import LoginForm from './LoginForm';
import './App.css'; // Ensure App.css is correctly imported
import { getElderVerificationCounts } from './Airtable';

const App = () => {
  const [elder, setElder] = useState(null);
  const [language, setLanguage] = useState(null);
  const [languageId, setLanguageId] = useState(null); // New state for language ID
  const [languageUuid, setLanguageUuid] = useState(null); // New state for language UUID
  const [backgroundImage, setBackgroundImage] = useState('');
  const [totalVerified, setTotalVerified] = useState(0);
  const [todayVerified, setTodayVerified] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-indexed month

    let background = '';
    if (month >= 4 && month <= 8) {
      background = 'spring.jpg';
    } else if (month >= 9 && month <= 10) {
      background = 'fall.jpg';
    } else {
      background = 'winter.jpg';
    }

    setBackgroundImage(background);
  }, []);

  useEffect(() => {
    const fetchVerifiedCount = async () => {
      if (elder) {
        const { totalVerified, totalVerifiedToday } = await getElderVerificationCounts(elder.id);
        setTotalVerified(totalVerified);
        setTodayVerified(totalVerifiedToday);
      }
    };

    fetchVerifiedCount();
  }, [elder]);

  const handleLogin = (elder) => {
    console.log('Elder after login:', elder); // Log elder object after login
    setElder(elder);
  };

  const handleLanguageSelect = (language, id, uuid) => {
    setLanguage(language);
    setLanguageId(id);
    setLanguageUuid(uuid);
  };

  const handleLogout = () => {
    setElder(null);
    setLanguage(null);
    setLanguageId(null); // Reset language ID on logout
    setLanguageUuid(null); // Reset language UUID on logout
    localStorage.removeItem('email');
  };

  const handleSwitchLanguage = () => {
    setLanguage(null);
    setLanguageId(null); // Reset language ID on switch
    setLanguageUuid(null); // Reset language UUID on switch
  };

  const isVerificationStep = elder && language && languageId && languageUuid;

  return (
    <div
      className="app"
      style={{
        backgroundImage: !isVerificationStep ? `url(${backgroundImage})` : 'none',
      }}
    >
      <Helmet>
        <title>Connected North - Language Verification Platform</title>
      </Helmet>
      {isVerificationStep && (
        <div className="header">
          <button onClick={handleSwitchLanguage} className="header-button">Switch Language</button>
          <button onClick={handleLogout} className="header-button">Logout</button>
        </div>
      )}
      {!elder ? (
        <LoginForm onLogin={handleLogin} />
      ) : !language || !languageId || !languageUuid ? (
        <LanguageSelection elder={elder} onSelectLanguage={handleLanguageSelect} />
      ) : (
        <>
          <TranslationVerification
            elder={elder}
            language={language}
            languageId={languageId} // Pass language ID to TranslationVerification
            languageUuid={languageUuid} // Pass language UUID to TranslationVerification
            onLogout={handleLogout}
            onSwitchLanguage={handleSwitchLanguage}
            setTotalVerified={setTotalVerified}   // Pass setTotalVerified
            setTodayVerified={setTodayVerified}   // Pass setTodayVerified
          />
          <div className="status-bar">
            Phrases Verified: {totalVerified} (Total) {todayVerified} (Today)
          </div>
        </>
      )}
    </div>
  );
};

export default App;
