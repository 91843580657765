import Airtable from 'airtable';

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base('appxMcfhx04xO63hf');

export const getTranslations = async (languageUuid) => {
  const records = await base('Translations').select({
    filterByFormula: `{Language UUID} = '${languageUuid}'`
  }).all(); // Use .all() to fetch all records in one call

  const phraseRecords = await base('Phrases').select({
    filterByFormula: `OR(${records.map(record => `RECORD_ID() = '${record.get('Phrase')[0]}'`).join(',')})`
  }).all();

  const phrases = phraseRecords.reduce((acc, record) => {
    acc[record.id] = record.get('Word');
    return acc;
  }, {});

  const translations = records.map(record => ({
    id: record.id,
    phrase: phrases[record.get('Phrase')[0]],
    translation: record.get('Translation'),
    language: record.get('Language'),
    elderVerified: record.get('Elder Verified') || [],
    elderSkipped: record.get('Elder Skipped') || [],
  }));

  return translations;
};

export const updateTranslation = async (id, updates) => {
  await base('Translations').update(id, updates);
};

export const getElder = async (email) => {
  const records = await base('Elders').select({
    filterByFormula: `{Email} = '${email}'`
  }).firstPage();

  return records.length > 0 ? records[0] : null;
};

export const getLanguageNames = async (languageIds) => {
  const records = await base('Indigenous Languages').select({
    filterByFormula: `OR(${languageIds.map(id => `RECORD_ID() = '${id}'`).join(',')})`
  }).firstPage();

  return records.map(record => ({
    id: record.id,
    name: record.get('Connected North Name') || record.get('Name'),
    uuid: record.get('uuid')
  }));
};

export const getElderVerificationCounts = async (elderId) => {
  const record = await base('Elders').find(elderId);
  return {
    totalVerified: record.get('Total Verified') || 0,
    totalVerifiedToday: record.get('Total Verified Today') || 0,
  };
};

export const getLanguageNameByUuid = async (uuid) => {
  const records = await base('Indigenous Languages').select({
    filterByFormula: `{uuid} = '${uuid}'`
  }).firstPage();

  if (records.length > 0) {
    return records[0].get('Connected North Name') || records[0].get('Name');
  }

  return '';
};

export const getVerifiedTranslations = async (elderId, language) => {
  // Fetch the elder's name using the elderId
  const elderRecord = await base('Elders').find(elderId);
  const elderName = elderRecord.get('Name');

  const records = await base('Translations').select({
    filterByFormula: `AND({Language UUID} = '${language}', FIND(";${elderName};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Elder Verified}), ", ", ";"), ";")))`
  }).firstPage();

  const translations = await Promise.all(records.map(async (record) => {
    const phraseRecord = await base('Phrases').find(record.get('Phrase')[0]);
    return {
      id: record.id,
      phrase: phraseRecord.get('Word'),
      translation: record.get('Translation'),
      language: record.get('Language'),
    };
  }));
  return translations;
};

export const addNewPhrase = async (phrase, translation, elderId, languageId) => {
  const phraseRecord = await base('Phrases').create({
    Word: phrase,
    'Added By': [elderId],
    'Added With': [languageId],
  });
  await base('Translations').create({
    'Phrase': [phraseRecord.id],
    'Translation': translation,
    'Elder Verified': [elderId],
    'Language': [languageId],
  });
};

export const checkPhraseExists = async (phrase) => {
  const records = await base('Phrases').select({
    filterByFormula: `{Word} = '${phrase}'`
  }).firstPage();

  return records.length > 0;
};
