import React, { useState, useEffect, useRef, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import AWS from 'aws-sdk';

const CustomAudioRecorder = ({ onSubmit, selectedMic, onMicChange }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [progress, setProgress] = useState('00:00');
  const [availableDevices, setAvailableDevices] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const wavesurferRef = useRef(null);
  const recordRef = useRef(null);
  const audioBlobRef = useRef(null);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  const createWaveSurfer = useCallback(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
    }
    wavesurferRef.current = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'violet',
      interact: false,
      cursorWidth: 0,
    });

    recordRef.current = wavesurferRef.current.registerPlugin(RecordPlugin.create({
      mimeType: 'audio/webm', // Use audio/webm for compatibility
      audioBitsPerSecond: 128000,
      sampleRate: 44100,
      scrollingWaveform: true, // Always enable scrolling waveform
      renderRecordedAudio: false,
    }));

    recordRef.current.on('record-end', (blob) => {
      audioBlobRef.current = blob;
      const audioUrl = URL.createObjectURL(blob);
      setMediaBlobUrl(audioUrl);
      setIsRecording(false);
    });

    recordRef.current.on('record-progress', (time) => {
      const formattedTime = [
        Math.floor((time % 3600000) / 60000), // minutes
        Math.floor((time % 60000) / 1000), // seconds
      ].map((v) => (v < 10 ? '0' + v : v)).join(':');
      setProgress(formattedTime);
    });
  }, []);

  useEffect(() => {
    createWaveSurfer();

    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      setAvailableDevices(devices);
      if (devices.length > 0 && !selectedMic) {
        onMicChange(devices[0].deviceId); // Set default mic
      }
    });

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [createWaveSurfer, onMicChange, selectedMic]);

  const startRecording = () => {
    if (recordRef.current) {
      recordRef.current.startRecording({ deviceId: selectedMic }).then(() => {
        setIsRecording(true);
      });
    } else {
      console.error('Record plugin not initialized');
    }
  };

  const stopRecording = () => {
    if (recordRef.current) {
      recordRef.current.stopRecording();
    } else {
      console.error('Record plugin not initialized');
    }
  };

  const handleReRecord = () => {
    setMediaBlobUrl(null);
    setCountdown(3);
    setTimeout(() => setCountdown(2), 1000);
    setTimeout(() => setCountdown(1), 2000);
    setTimeout(() => {
      setCountdown(null);
      startRecording();
    }, 3000);
  };

  const uploadToS3 = async (blob) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `recordings/${Date.now()}.webm`, // Change to webm format
      Body: blob,
      ContentType: 'audio/webm'
    };

    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const handleSubmit = async () => {
    if (audioBlobRef.current) {
      const s3Url = await uploadToS3(audioBlobRef.current);
      onSubmit(s3Url);
    }
  };

  return (
    <div>
      {!mediaBlobUrl ? (
        <div>
          <h1>Press Record to start recording 🎙️</h1>
          <select id="mic-select" value={selectedMic} onChange={(e) => onMicChange(e.target.value)}>
            <option value="" hidden>Select mic</option>
            {availableDevices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || device.deviceId}
              </option>
            ))}
          </select>
          <button id="record" onClick={() => { setCountdown(3); setTimeout(() => setCountdown(2), 1000); setTimeout(() => setCountdown(1), 2000); setTimeout(() => { setCountdown(null); startRecording(); }, 3000); }} disabled={isRecording}>
            Record
          </button>
          {countdown && <div className="modal-overlay"><h2>{countdown}</h2></div>}
          <div id="waveform" style={{ width: '100%', height: '200px', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '4px', marginTop: '1rem' }}></div>
          <p id="progress">{progress}</p>
          {isRecording && (
            <button onClick={stopRecording} className="audio-recorder-button" style={{ marginTop: '20px' }}>
              Finish Recording
            </button>
          )}
        </div>
      ) : (
        <div>
          <h3>Review the Recording:</h3>
          <audio src={mediaBlobUrl} controls style={{ marginBottom: '20px' }} /><br></br>
          <button onClick={handleReRecord} className="audio-recorder-button record-again" style={{ backgroundColor: 'grey' }}>
            Record Again
          </button>
          <button onClick={handleSubmit} className="audio-recorder-button submit-and-continue" style={{ backgroundColor: 'green', marginLeft: '10px' }}>
            Submit and Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomAudioRecorder;
