// LanguageSelection.js
import React, { useEffect, useState } from 'react';
import { getLanguageNames } from './Airtable';

const LanguageSelection = ({ elder, onSelectLanguage }) => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        if (elder && elder.fields && elder.fields['Language(s) Spoken']) {
          console.log('Languages Spoken by Elder:', elder.fields['Language(s) Spoken']); // Log the languages
          const languageNames = await getLanguageNames(elder.fields['Language(s) Spoken']);
          console.log('Fetched Language Names:', languageNames); // Log the fetched language names
          setLanguages(languageNames);
        } else {
          console.log('Elder does not have "Languages Spoken" field or it is empty.');
        }
      } catch (error) {
        console.error('Error fetching languages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, [elder]);

  if (loading) {
    return <p>Loading languages...</p>;
  }

  if (languages.length === 0) {
    return <p>No languages available.</p>;
  }

  return (
    <div className="language-selection-box">
      <h2>Select a Language</h2>
      {languages.map(language => (
        <button key={language.id} onClick={() => onSelectLanguage(language.name, language.id, language.uuid)}>
          {language.name}
        </button>
      ))}
    </div>
  );
};

export default LanguageSelection;
