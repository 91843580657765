import React, { useState, useEffect, useCallback } from 'react';
import {
  getTranslations,
  updateTranslation,
  getElderVerificationCounts,
  getLanguageNameByUuid,
  getVerifiedTranslations,
  addNewPhrase,
  checkPhraseExists,
} from './Airtable';
import CustomAudioRecorder from './CustomAudioRecorder';
import { capitalizeFirstLetter } from './utils'; // Import the utility function
import './App.css'; // Ensure you are importing the combined App.css

const TranslationVerification = ({ elder, language, languageId, languageUuid, onLogout, onSwitchLanguage, setTotalVerified, setTodayVerified }) => {
  const [translations, setTranslations] = useState({ needingVerification: [], needingTranslation: [] });
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [editMode, setEditMode] = useState(false); // Track if in edit mode
  const [newTranslation, setNewTranslation] = useState('');
  const [selectedMic, setSelectedMic] = useState('');
  const [languageName, setLanguageName] = useState(''); // Store the actual language name
  const [verifiedTranslations, setVerifiedTranslations] = useState([]); // Store verified translations
  const [newPhrase, setNewPhrase] = useState('');
  const [newPhraseTranslation, setNewPhraseTranslation] = useState('');
  const [showAddPhraseForm, setShowAddPhraseForm] = useState(false); // Track the state of add phrase form

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching translations for language:', languageUuid);
      const data = await getTranslations(languageUuid);
      console.log('Fetched translations:', data);

      // Separate translations needing verification and those needing translation
      const translationsNeedingVerification = data.filter(
        translation =>
          translation.translation &&
          !translation.elderVerified.includes(elder.id) &&
          !translation.elderSkipped.includes(elder.id)
      );

      const translationsNeedingTranslation = data.filter(
        translation => !translation.translation
      );

      // Sort the translations alphabetically
      translationsNeedingVerification.sort((a, b) => a.phrase.localeCompare(b.phrase));
      translationsNeedingTranslation.sort((a, b) => a.phrase.localeCompare(b.phrase));

      setTranslations({
        needingVerification: translationsNeedingVerification,
        needingTranslation: translationsNeedingTranslation
      });

      setCurrentTranslation(translationsNeedingVerification[0] || translationsNeedingTranslation[0] || null);

      const verified = await getVerifiedTranslations(elder.id, languageUuid);
      verified.sort((a, b) => a.phrase.localeCompare(b.phrase)); // Sort the dropdown alphabetically
      setVerifiedTranslations(verified);
    };

    const fetchLanguageName = async () => {
      const name = await getLanguageNameByUuid(languageUuid);
      setLanguageName(name);
    };

    fetchData();
    fetchLanguageName();
  }, [languageUuid, elder.id]);

  useEffect(() => {
    if (currentTranslation) {
      setNewTranslation(currentTranslation.translation || '');
    }
  }, [currentTranslation]);

  const updateVerifiedCount = useCallback(async () => {
    const { totalVerified, totalVerifiedToday } = await getElderVerificationCounts(elder.id);
    console.log('Updated verified count:', { totalVerified, totalVerifiedToday });
    setTotalVerified(totalVerified);
    setTodayVerified(totalVerifiedToday);
  }, [elder.id, setTotalVerified, setTodayVerified]);

  const handleApproval = async (isApproved) => {
    if (isApproved) {
      await updateTranslation(currentTranslation.id, {
        'Elder Verified': [...(currentTranslation.elderVerified || []), elder.id]
      });
      removeCurrentTranslation();
      setIsRecording(true);
      updateVerifiedCount(); // Update verified count after removal

      // Update verified translations
      setVerifiedTranslations((prevVerified) => {
        const updatedVerified = [...prevVerified, currentTranslation];
        updatedVerified.sort((a, b) => a.phrase.localeCompare(b.phrase)); // Sort the dropdown alphabetically
        return updatedVerified;
      });
    } else {
      setEditMode(true); // Set to edit mode when Wrong is clicked
    }
  };

  const handleSkip = async () => {
    await updateTranslation(currentTranslation.id, {
      'Elder Skipped': [...(currentTranslation.elderSkipped || []), elder.id],
    });
    removeCurrentTranslation();
    updateVerifiedCount(); // Update verified count after removal
    moveToNextTranslation();
  };

  const handleRecordingSubmit = async (s3Url) => {
    await updateTranslation(currentTranslation.id, {
      'Spoken Sample': [
        {
          url: s3Url,
          filename: 'recording.webm'
        }
      ]
    });

    setIsRecording(false);
    moveToNextTranslation();
  };

  const handleEditSubmit = async () => {
    await updateTranslation(currentTranslation.id, {
      'Translation': newTranslation,
      'Elder Verified': [...(currentTranslation.elderVerified || []), elder.id]
    });
    setEditMode(false);
    setIsRecording(true); // After edit, move to recording screen
    removeCurrentTranslation();
    updateVerifiedCount(); // Update verified count after removal

    // Update verified translations
    setVerifiedTranslations((prevVerified) => {
      const updatedVerified = [...prevVerified, currentTranslation];
      updatedVerified.sort((a, b) => a.phrase.localeCompare(b.phrase)); // Sort the dropdown alphabetically
      return updatedVerified;
    });
  };

  const handleNewTranslationSubmit = async () => {
    await updateTranslation(currentTranslation.id, {
      'Translation': newTranslation,
      'Elder Verified': [...(currentTranslation.elderVerified || []), elder.id]
    });
    setIsRecording(true); // Move to recording screen after saving the translation
    removeCurrentTranslation();
    updateVerifiedCount(); // Update verified count after removal

    // Update verified translations
    setVerifiedTranslations((prevVerified) => {
      const updatedVerified = [...prevVerified, currentTranslation];
      updatedVerified.sort((a, b) => a.phrase.localeCompare(b.phrase)); // Sort the dropdown alphabetically
      return updatedVerified;
    });
  };

  const removeCurrentTranslation = () => {
    setTranslations(prevTranslations => {
      const updatedVerification = prevTranslations.needingVerification.filter(t => t.id !== currentTranslation.id);
      const updatedTranslation = prevTranslations.needingTranslation.filter(t => t.id !== currentTranslation.id);
      return {
        needingVerification: updatedVerification,
        needingTranslation: updatedTranslation,
      };
    });
  };

  const moveToNextTranslation = () => {
    const allTranslations = [...translations.needingVerification, ...translations.needingTranslation];
    const nextIndex = allTranslations.indexOf(currentTranslation) + 1;
    setCurrentTranslation(allTranslations[nextIndex] || null);
  };

  const handlePhraseClick = (translation) => {
    setCurrentTranslation(translation);
    setEditMode(false);
    setIsRecording(false);
    if (!translation.translation) {
      setNewTranslation(''); // Reset newTranslation if translation is empty
    }
  };

  const handleReviewPhraseClick = (translationId) => {
    const translation = verifiedTranslations.find(t => t.id === translationId);
    if (translation) {
      setCurrentTranslation(translation);
      setIsRecording(false);
      setEditMode(false);
      if (!translation.translation) {
        setNewTranslation('');
      }
    }
  };

  const handleAddNewPhrase = async () => {
    if (await checkPhraseExists(newPhrase)) {
      alert('This phrase already exists.');
      return;
    }

    await addNewPhrase(newPhrase, newPhraseTranslation, elder.id, languageId);
    alert('New phrase added successfully.');
    setNewPhrase('');
    setNewPhraseTranslation('');
    setShowAddPhraseForm(false); // Hide the form after adding a new phrase
    // Optionally, update the list of translations or any other state here
  };

  useEffect(() => {
    updateVerifiedCount();
  }, [updateVerifiedCount]);

  return (
    <div className="translation-verification">
      <div className="side-menu">
        <button onClick={() => setShowAddPhraseForm(prev => !prev)} className="header-button">+ Add Word/Phrase</button>
        {verifiedTranslations.length > 0 && (
          <>
            <h3>Review Completed Phrases</h3>
            <select onChange={(e) => handleReviewPhraseClick(e.target.value)}>
              <option value="">Select a phrase</option>
              {verifiedTranslations.map(translation => (
                <option key={translation.id} value={translation.id}>
                  {capitalizeFirstLetter(translation.phrase)}
                </option>
              ))}
            </select>
          </>
        )}
        {translations.needingVerification.length > 0 && (
          <>
            <h3>Phrases Awaiting Confirmation</h3>
            <ul>
              {translations.needingVerification.map(translation => (
                <li
                  key={translation.id}
                  className={currentTranslation && currentTranslation.id === translation.id ? 'active' : ''}
                  onClick={() => handlePhraseClick(translation)}
                >
                  {capitalizeFirstLetter(translation.phrase)}
                </li>
              ))}
            </ul>
          </>
        )}
        {translations.needingTranslation.length > 0 && (
          <>
            <h3>Phrases Needing Translation</h3>
            <ul>
              {translations.needingTranslation.map(translation => (
                <li
                  key={translation.id}
                  className={currentTranslation && currentTranslation.id === translation.id ? 'active' : ''}
                  onClick={() => handlePhraseClick(translation)}
                >
                  {capitalizeFirstLetter(translation.phrase)}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="main-content">
        {showAddPhraseForm ? (
          <div className="edit-container">
            <h3>Add New Word/Phrase</h3>
            <input
              type="text"
              value={newPhrase}
              onChange={(e) => setNewPhrase(e.target.value)}
              placeholder="Enter new word/phrase"
            />
            <input
              type="text"
              value={newPhraseTranslation}
              onChange={(e) => setNewPhraseTranslation(e.target.value)}
              placeholder="Enter translation"
            />
            <button onClick={handleAddNewPhrase}>Add Phrase</button>
            <button onClick={() => setShowAddPhraseForm(false)}>Cancel</button>
          </div>
        ) : currentTranslation ? (
          isRecording ? (
            <div>
              <div className="phrase">{capitalizeFirstLetter(newTranslation)}</div> {/* Show edited translation */}
              <CustomAudioRecorder
                onSubmit={handleRecordingSubmit}
                selectedMic={selectedMic}
                onMicChange={setSelectedMic}
              />
            </div>
          ) : editMode ? ( // Show edit input if in edit mode
            <div className="edit-container">
              <div className="phrase">{capitalizeFirstLetter(currentTranslation.phrase)}</div> {/* Show English word above edit box */}
              <input
                type="text"
                value={newTranslation}
                onChange={(e) => setNewTranslation(e.target.value)}
                placeholder="Enter translation"
              />
              <button onClick={handleEditSubmit}>Submit</button>
            </div>
          ) : (
            <div>
              <div className="phrase-container">
                <div className="phrase-section">
                  <div className="phrase">{capitalizeFirstLetter(currentTranslation.phrase)}</div>
                  <h4 className="source-language">English</h4>
                </div>
                <div className="translation-section">
                  <div className="translation">{capitalizeFirstLetter(currentTranslation.translation)}</div>
                  <h4 className="source-language">{capitalizeFirstLetter(languageName)}</h4>
                </div>
              </div>
              {currentTranslation.translation ? (
                <div className="buttons">
                  <button className="correct" onClick={() => handleApproval(true)}>Correct</button>
                  <button className="not-sure" onClick={handleSkip}>Not sure</button>
                  <button className="wrong" onClick={() => handleApproval(false)}>Wrong</button>
                </div>
              ) : (
                <div className="edit-container">
                  <input
                    type="text"
                    value={newTranslation}
                    onChange={(e) => setNewTranslation(e.target.value)}
                    placeholder="Enter translation"
                  />
                  <button onClick={handleNewTranslationSubmit}>Submit</button>
                </div>
              )}
            </div>
          )
        ) : (
          <p>No translations available for verification.</p>
        )}
      </div>
    </div>
  );
};

export default TranslationVerification;
